import styles from "./Input/Input.module.scss";
import cn from "clsx";

interface SelectProps {
  isFullWidth?: boolean;
  value: string;
  onChangeFunc: (value: string) => void;
  options: string[];
  disabled?: boolean;
}

export default function Select({
  isFullWidth = true,
  value,
  onChangeFunc,
  options,
  disabled = false
}: SelectProps) {
  return (
    <div className={cn(styles.SelectContainer, "border", "border-neutral-200")}>
      <select
        className={`appearance-none lining-nums ${
          isFullWidth && "w-full"
        } text-stone-500 bg-white text-lg leading-loose tracking-tight  outline-none`}
        value={value}
        onChange={(e) => onChangeFunc(e.target.value)}
        disabled={disabled}
      >
        {options.map((option, i) => (
          <option value={option} key={i}>
            {option}
          </option>
        ))}
      </select>
      <div className="absolute right-3 bottom-[50%] translate-y-[50%]">
        <svg
          width="13"
          height="9"
          viewBox="0 0 13 9"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="Vector_832"
            d="M12 1L6.62222 7L0.999999 1"
            stroke="#686868"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      </div>
    </div>
  );
}
