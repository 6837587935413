
import { DEGREE_TYPES, PROGRAM_DROPDOWN } from "../../ProgramMenu/constants";

const SELECT_DEGREE = "Select Degree";
const SELECT_PROGRAM = "Select Program";

const precedentSelectedPrograms = (associatedPrograms: Array<ProgramDetails>) =>
  associatedPrograms.map((selectedProgram) =>
    selectedProgram.attributes.BasicDetails?.Heading.toUpperCase()
  );

const getProgramId = ({
  isLandingPage,
  associatedPrograms,
  program,
}: {
  isLandingPage: boolean;
  associatedPrograms: Array<ProgramDetails>;
  program: String;
}) =>
  isLandingPage
    ? associatedPrograms.find(
        (selectedProgram) =>
          selectedProgram.attributes.BasicDetails?.Heading.toUpperCase() ==
          String(program).toUpperCase()
      )?.attributes.BasicDetails?.PROGRAM_ID || ''
    : PROGRAM_DROPDOWN.find((e) => String(e.title).toLowerCase() == String(program).toLowerCase())
        ?.id || "";

const filterSelectedPrograms = ({
  associatedPrograms,
  degree = SELECT_DEGREE,
}: {
  associatedPrograms: Array<ProgramDetails>;
  degree: string;
}) => {
  return associatedPrograms.filter(
    (programOption) =>
      (programOption.attributes.BasicDetails?.ProgramType === DEGREE_TYPES[degree]?.id ||
        degree == SELECT_DEGREE) &&
      !!programOption.attributes.BasicDetails?.Heading
  );
};

export { precedentSelectedPrograms, getProgramId, SELECT_DEGREE, SELECT_PROGRAM, filterSelectedPrograms };
