export default function strapiSingleAttribute(node) {
  if (!node) return null;
  try {
    return (node.data[0] || node.data).attributes;
  } catch (e) {
    return null;
  }
}

export function stripHtmlTags(html: string): string {
  return html.replace(/<[^>]*>?/gm, '');
}